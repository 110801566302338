import {ref, onMounted} from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import {api} from '@/services/api'

export default function useSlider() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'slide_id',
            width: '50',
        },
        {
            text: 'รูปสินค้า',
            value: 'slide_img',
            width: '110',
        },
        {
            text: 'ชื่อ',
            value: 'slide_name',
            width: '200',
        },
        {
            text: 'ข้อมูล',
            value: 'slide_detail',
            width: '150',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '70',
            align: 'end fixed',
        },
    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const isDelete = ref(false)

    const getSliders = () => {
        loading.value = true
        api.get({
            path: '/admin/slides',
            param: `?search=${textSearch.value}`,
        }).then(({data}) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getSliders()
    })

    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isEdit,
        dataEdit,
        footer,
        dataList,
        isDelete,
        loading,
        getSliders,
    }
}
