<template>
  <div>
    <v-dialog v-model='isAddNewSlider' persistent max-width='800'>
      <v-card>
        <v-card-title>
          <span>เพิ่มไสลด์</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddNewSlider' @submit.prevent='createSlider'>
          <input
              ref='selectImage'
              type='file'
              accept='image/*'
              style='display: none'
              @change='previewFiles'
          />
          <v-row class='mx-2 my-2'>
            <v-col cols='12' class='d-flex align-center justify-center'>
              <v-hover
                  v-slot='{ hover }'
              >
                <v-img
                    v-if='blob_image'
                    contain
                    class='rounded-lg set-block'
                    :src='blob_image'
                    height='200px'
                    width='100%'
                >
                  <v-fade-transition>
                    <v-overlay
                        v-if='hover'
                        absolute
                        color='#212121'
                    >
                      <div class='align-self-center'>
                        <v-btn
                            :class="{ 'show-btns': hover }"
                            icon
                            color='primary'
                            @click='$refs.selectImage.click()'
                        >
                          <v-icon
                              :class="{ 'show-btns': hover }"
                              color='transparent'
                          >
                            {{ mdiPencilOutline }}
                          </v-icon>
                        </v-btn>
                        <v-btn
                            :class="{ 'show-btns': hover }"
                            icon
                            color='primary'
                            @click="blob_image = ''"
                        >
                          <v-icon
                              :class="{ 'show-btns': hover }"
                              color='transparent'
                          >
                            {{ mdiDeleteOutline }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
                <v-card
                    v-else
                    :elevation='hover ? 7 : 0'
                    :class="{ 'on-hover': hover }"
                    class='mx-auto upload-files '
                    height='200px'
                    outlined
                    width='100%'
                    @click='$refs.selectImage.click()'
                >
                  <div
                      align='center'
                      class='pt-12'
                  >
                    <div class='text-lg '>
                      อัพโหลดรูปภาพ
                    </div>
                    <div class='text-md error--text'>
                      ไฟล์ขนาดไม่เกิน 2 MB
                    </div>
                    <v-icon
                        x-large
                        color='primary'
                        class='pt-2 '
                    >
                      {{ mdiCloudUploadOutline }}
                    </v-icon>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols='12'>
              <v-text-field filled v-model='slide_name' dense label='ชื่อ'
                            :rules='[required]'
                            placeholder='ชื่อ' hide-details='auto'></v-text-field>
            </v-col>
            <v-col cols='12'>
              <v-textarea filled v-model='slide_detail' dense label='ข้อมูล'
                          placeholder='ข้อมูล' hide-details></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit'
                   :loading='loading'
                   :disabled='loading || !slider_image.length'>บันทึก
            </v-btn>
            <v-btn color='secondary' outlined @click='$emit(`update:isAddNewSlider`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@core/utils/validation'
import {api} from '@/services/api'
import {mdiCloudUploadOutline, mdiPencilOutline, mdiDeleteOutline, mdiPlus} from '@mdi/js'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewSlider',
    event: 'update:isAddNewSlider',
  },
  props: {
    isAddNewSlider: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {root, emit}) {
    const slide_name = ref('')
    const slide_detail = ref('')
    const slider_image = ref([])
    const blob_image = ref(null)
    const formAddNewSlider = ref(null)
    const loading = ref(false)

    const createSlider = () => {
      const isFormValid = formAddNewSlider.value.validate()
      if (!isFormValid) return
      loading.value = true
      const formData = new FormData()
      formData.append('slide_name', slide_name.value)
      formData.append('slide_detail', slide_detail.value)
      formData.append('slide_img', slider_image.value[0])

      api.post({
        path: '/admin/slide',
        body: formData,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewSlider', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })
    }

    const previewFiles = event => {
      slider_image.value = []
      slider_image.value.push(event.target.files[event.target.files.length - 1])
      blob_image.value = URL.createObjectURL(
          event.target.files[event.target.files.length - 1],
      )
    }

    watch(() => props.isAddNewSlider, async value => {
      if (value) {
        clearData()
      }
    })

    const clearData = () => {
      slider_image.value = []
      slide_name.value = ''
      slide_detail.value = ''
      blob_image.value = null
    }

    return {
      slide_name,
      slide_detail,
      formAddNewSlider,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiCloudUploadOutline,
      mdiPlus,
      blob_image,
      slider_image,
      loading,
      previewFiles,
      createSlider,
      required,
    }
  },

}
</script>

<style scoped>
.set-block {
  max-width: 100%;
  max-height: 200px;
  border: 1px dashed #212121;
  border-radius: 10px;
  background-color: #d6d6d6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
