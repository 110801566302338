<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>
          ข้อมูลไสลด์
        </span>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAdd = true'>เพิ่มไสลด์</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getSliders'
                        label='ค้นหาไสลด์'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.slide_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.slide_img`]='{ item }'>
          <v-img :src='item.slide_img'
                 class='elevation-4 rounded-lg my-1'
                 width='50'
                 height='50'>
          </v-img>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  :disabled='item.shop_id === 1'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isDelete = true'
                  v-on='on'
              >
                <v-icon>{{ mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ `ลบ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewSlider v-model='isAdd' @onAdd='getSliders'/>
    <EditSlider v-model='isEdit' :dataEdit='dataEdit' @onUpdate='getSliders'/>
    <DeleteSlider v-model='isDelete' :data-update='dataEdit' @onUpdate='getSliders'/>
  </div>
</template>

<script>
import useSlider from './useSlider'
import {mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiTextBoxCheck} from '@mdi/js'
import AddNewSlider from './AddNewSlider'
import EditSlider from './EditSlider'
import DeleteSlider from './DeleteSlider'

export default {
  props: {},
  components: {
    EditSlider,
    DeleteSlider,
    AddNewSlider,
  },
  setup(_, {root, emit}) {
    return {
      ...useSlider(),
      mdiCheck,
      mdiTextBoxCheck,
      mdiPencilOutline,
      mdiDeleteOutline,
    }
  },

}
</script>

<style scoped>

</style>
